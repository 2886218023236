//redux state slice for the ui

import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        previousWindow: 'content-page-main',
        currentWindow: 'content-page-main',
        hamburgerState: 0,
        slidemenuopen: false
    },
    reducers: {
        changeWindow(state, action) { //the payload is { newWindow: 'abcd' }
            state.previousWindow = state.currentWindow
            state.currentWindow = action.payload.newWindow
        },
        toggleHamburgerState(state, action) {
            state.hamburgerState = (state.hamburgerState) ? 0 : 1
        },
        toggleSlideMenuOpen(state, action) {
            state.slidemenuopen = (state.slidemenuopen) ? false : true
        }
    }
})

const { actions, reducer } = uiSlice;
export const { changeWindow, toggleHamburgerState, toggleSlideMenuOpen } = actions;
export default reducer;