import React from 'react';
import { Link } from 'react-router-dom';
//import { ReactComponent as Icon } from '../resources/loading-bars.svg';
import ReactLoading from 'react-loading';

function _renderChildren(props) {
    if (!props.results) { return null; }
    const sval = props.searchVal;
    const svallen = sval.length;

    const retval = props.results.map((r, i) => (
        <Link className="link-inherit" to={"/vendor/" + r.vendor.properties.uuid + "/" + r.item.item_uuid} key={i}>
            <div className="pt-2 pl-2 pr-2 d-flex justify-content-between">
                <span>  
                    <span>{r.item.item_name.slice(0, r.item.item_name.toUpperCase().indexOf(sval.toUpperCase()))}</span>
                    <span className="font-weight-bold">{r.item.item_name.slice ( r.item.item_name.toUpperCase().indexOf(sval.toUpperCase()), r.item.item_name.toUpperCase().indexOf(sval.toUpperCase()) + svallen)}</span>
                    <span>{r.item.item_name.slice(r.item.item_name.toUpperCase().indexOf(sval.toUpperCase()) + svallen)}</span>
                </span>
                <span className="text-muted">{r.vendor.properties.name}</span>
                <span className="text-primary">{r.item.item_price_each}</span>
            </div>
        </Link>
    ));

    return retval;
}

export default function SearchAhead(props) {
    //props.search is a promise that should resolve to an array of {item, vendor}

    if (props.loading) { console.log('loading'); }


    //if (!props.results || (props.results.length === 0 && !props.loading)) { return null; }

    return (
        <div style={{
            backgroundColor: "white",
            zIndex: '10000'
        }}
            className={props.className}>
            {(props.results) && (!props.loading) && _renderChildren(props)}
            {(props.results) && <div className="pb-2"></div>}
            {(props.loading) && <div className="d-flex justify-content-center"><ReactLoading color="grey" type="bars" /></div>}
        </div>
    )
}
