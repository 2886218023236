import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { toggleHamburgerState, toggleSlideMenuOpen } from '../redux/uiSlice'
function HamburgerButton(props) {
    //stateful variable to keep track of the button opening and closing
    //const [uistate, setuistate] = useGlobal('ui');
    //const buttonopen = uistate.hamburgerState;
    const buttonopen = useSelector( state => state.uiSlice.hamburgerState )
    const dispatch = useDispatch()

    const handleButtonPress = () => {
        dispatch(toggleHamburgerState())
        dispatch(toggleSlideMenuOpen())
    }
    /*const handleButtonPressOld = () => {
        dispatch(toggleHamburgerState())
        dispatch(toggleSlideMenuOpen())
       if (buttonopen) {
            let newuistate = {
                ...uistate,
                currentWindow: uistate.previousWindow,
                previousWindow: 'content-page-menu',
                hamburgerState: 0,
                slidemenuopen: false
            };
            setuistate(newuistate);
        } else {
            let newuistate = {
                ...uistate,
                currentWindow: 'content-page-menu',
                previousWindow: uistate.currentWindow,
                hamburgerState: 1,
                slidemenuopen: true
            };
            setuistate(newuistate);
        }
    };*/

    //capturing the styles for the button
    const styles = {
        menuContainer: {
            padding: '0',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between'
        },
        btnContainer: {
            height: '32px',
            width: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '4px',
        },
        line: {
            height: '2px',
            width: '20px',
            background: 'black',
            transition: 'all 0.2s ease',
        },
        lineTop: {
            transform: buttonopen ? 'rotate(45deg)' : 'none',
            transformOrigin: 'top left',
            marginBottom: '5px',
        },
        lineMiddle: {
            opacity: buttonopen ? 0 : 1,
            transform: buttonopen ? 'translateX(-16px)' : 'none',
        },
        lineBottom: {
            transform: buttonopen ? 'translateX(-1px) rotate(-45deg)' : 'none',
            transformOrigin: 'top left',
            marginTop: '5px',
        },
    };

    //return the output JSX
    return (
        <div style={styles.btnContainer} onClick={() => { handleButtonPress() }}>
            <div style={{ ...styles.line, ...styles.lineTop }} />
            <div style={{ ...styles.line, ...styles.lineMiddle }} />
            <div style={{ ...styles.line, ...styles.lineBottom }} />
        </div>
    );
}

export default HamburgerButton;