import React from 'react';
import { BaseControl } from 'react-map-gl';
import { Link, Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import closeimage from '../resources/close_icon_small.png';

//the white pin behind the vendor image
const MarkerImage = props => (
    <svg
        aria-hidden="true"
        width="80px"

        style={{
            msTransform: 'rotate(360deg)',
            WebkitTransform: 'rotate(360deg)',
        }}
        viewBox="0 0 15 15"
        transform="rotate(360)"
        {...props}
    >
        <path
            d="M7.5 0C5.068 0 2.23 1.486 2.23 5.27c0 2.568 4.054 8.244 5.27 9.73 1.081-1.486 5.27-7.027 5.27-9.73C12.77 1.487 9.932 0 7.5 0z"
            fill="white"
        />
        <path fill="rgba(0, 0, 0, 0)" d="M0 0h15v15H0z" />
    </svg>
);

class VendorMarker extends BaseControl {
    constructor(props) {
        super(props);
        this.state = {
            popupInfo: false,
            redirectToVendorPage: false
        };
    }

    toggleShowPopup() {
        const { popupInfo } = this.state;
        this.setState({ popupInfo: !popupInfo });
    };

    _renderRedirect(v) {
        return (
            <Redirect to={"/vendor/" + v.properties.uuid} />
        );
    }
    _renderPopup(v) {
        const { popupInfo } = this.state;

        const popUpUnderlayStyle = {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            cursor: 'pointer',
            zIndex: '100'
        };

        const popUpStyle = {
            position: 'absolute',
            top: '60px',
            left: '10px',
            width: 'calc(100vw - 20px)',
            height: '310px',
            borderRadius: '5px',
            backgroundColor: 'white'
        };

        return (
            popupInfo &&
            (
                <div style={popUpUnderlayStyle} onClick={() => {this.toggleShowPopup()}}>
                    <div style={popUpStyle}>
                        <img style={{ position: 'absolute', top: '0px', right: '0px', marginRight: '-10px', marginTop: '-10px' }} src={closeimage} onClick={() => {this.toggleShowPopup()}} alt="close the vendor popup" />
                        <div className="d-flex mt-2">
                            <div className="ml-auto mr-auto"><img src={process.env.PUBLIC_URL + v.properties.uuid + '-circle-50.png'} alt='vendor' /></div>
                        </div>
                        <p>Name's page</p>
                        <p>Number of stars</p>
                        <p>Description of what they sell</p>
                        <p>Popular products</p>
                        <Link to={"/vendor/" + v.properties.uuid} className="link-inherit"><Button className="sm">Vendor Page</Button></Link>
                    </div>
                </div>
            )
        );
    }

    _render() {
        const { longitude, latitude, imgsrc, vendorobject=false } = this.props;
        const [x, y] = this._context.viewport.project([longitude, latitude]);

        const markerStyle = {
            position: 'absolute',
            left: x - 40,
            top: y - 80,
            width: '80px',
            height: '80px' //a bit extra height 
        };

        //this uses images that are 50x50, circular, with transparent background
        const vendorImageStyle = {
            position: "absolute",
            top: 3,
            left: 15
        }

        const _handleClick = e => {
            //this.setState({popupInfo: !popupInfo});
            //vendorobject && this.toggleShowPopup();
            //vendorobject && this.context.history.push('/vendor/' + vendorobject.properties.uuid);
            this.setState({ ...this.state, redirectToVendorPage: true });
            return false;
        };

        return (
            <>
                <div ref={this._containerRef}
                    style={markerStyle} onClick={(e) => _handleClick(e)}>
                    {vendorobject && this.state.redirectToVendorPage && this._renderRedirect(vendorobject)}
                    <MarkerImage />
                    <img src={process.env.PUBLIC_URL + imgsrc + '.png'} style={vendorImageStyle} alt='vendor' />
                </div>
                {vendorobject && this._renderPopup(vendorobject)}
            </>
        );
    }
}

export default VendorMarker;

