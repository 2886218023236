import React, { useState } from 'react';
import { InlineIcon } from '@iconify/react';
import starIcon from '@iconify/icons-mdi/star';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'

import { useDispatch } from 'react-redux'
import { addItem } from '../redux/cartSlice'

import { toast } from 'react-toastify';

function ProductCard(props) {
    const [addToCartCount, setAddToCartCount] = useState(1);
    //const [cartState, setCartState] = useGlobal('cart');
    //const cartState = useSelector( state => state.cartSlice )
    const productVendor = props.vendor;
    const productDetails = props.product;

    const dispatch = useDispatch();


    /*const productDetails = {
        "item_uuid": "f5cfae43-d369-4908-ba17-892e322bae73",
        "item_name": "Pink Lipstick",
        "item_image": "lipstick.jpeg",
        "item_price_each": 49.99,
        "item_rating": 3.6,
        "item_qty_max": 5,
        "item_desc": "This is a Revlon amazing red lipstick which is perfect for days and nights."
    };*/

    const addToCart = (prd, qty, vnd) => {
        dispatch(addItem({ //{ vendor_name, vendor_image, vendor_uuid, item_uuid, item_name, item_price_each, item_image, item_qty, item_add_dttm }
            vendor_name: productVendor.properties.name,
            vendor_image: productVendor.properties.iconfull,
            vendor_uuid: productVendor.properties.uuid,
            item_uuid: productDetails.item_uuid,
            item_name: productDetails.item_name,
            item_price_each: productDetails.item_price_each,
            item_image: 'linktoimage',
            item_qty: addToCartCount, //what is the cart qty?
            item_add_dttm: Date.now()            
        }));
        toast.info('Added item to cart', {
            position: 'bottom-center',
            autoClose: 2000,
        })
        /*let newCartState = Array.from(cartState);
        //first see if the vendor is in the cart
        //let existingVendor = newCartState.find((v) => (productDetails.vendor_uuid === v.vendor_uuid));
        let existingVendor = newCartState.find((v) => (productVendor.properties.uuid === v.vendor_uuid));

        if (!existingVendor) { //new vendor, need to add the vendor first
            let newVendor = {
                items: [],
                vendor_name: productVendor.properties.name,
                vendor_image: productVendor.properties.iconfull,
                vendor_uuid: productVendor.properties.uuid
            }
            newCartState.push(newVendor);
            existingVendor = newVendor;
        }

        let itemRef = existingVendor.items.find((i) => (productDetails.item_uuid === i.item_uuid));

        if (!itemRef) { //have not yet added this item
            //todo validate cart quantity not exceeded
            let newItem = {

                item_uuid: productDetails.item_uuid,
                item_name: productDetails.item_name,
                item_price_each: productDetails.item_price_each,
                item_image: 'linktoimage',
                item_qty: addToCartCount, //what is the cart qty?
                item_add_dttm: Date.now()
            }
            existingVendor.items.push(newItem);
        } else { //have already added the item, time to update the count

            itemRef.item_qty = itemRef.item_qty + addToCartCount; //todo: validate that it's not above max
        }

        //setCartState(newCartState);
        //console.log(newCartState);*/
    }

    const changeAddCartQuantity = (qty) => {
        let natcc = addToCartCount;
        natcc += qty;
        if (natcc < 1) { return; }
        setAddToCartCount(natcc);
    }

    const styles = {
        productPrice: {
            color: 'blue',
            fontWeight: '700'
        },
        productDescription: {
            color: 'gray',
            fontSize: '0.8em'
        },
        addToCartButton: {
            backgroundColor: 'blue',
            color: 'white',
            width: '50%',
            borderRadius: '5px'
        },
        displayInline: { display: 'inline' }
    };
    return (
        <Card className="m-2 p-2">
            <Card.Img variant="top" src={process.env.PUBLIC_URL + productDetails.item_uuid + ".jpg"} />
            <Card.Body>

                <Card.Title>{productDetails.item_name}

                    <div style={styles.displayInline}>
                        {Array.apply(null, { length: Math.floor(productDetails.item_rating) }).map((e, i) => (
                            <InlineIcon icon={starIcon} color="gold" key={'g' + i}/>
                        ))}
                        {Array.apply(null, { length: 5 - Math.floor(productDetails.item_rating) }).map((e, i) => (
                            <InlineIcon icon={starIcon} color="gray" key={'r' + i} />
                        ))}
                    </div>

                    {/*<span style={{ ...styles.productPrice, display: 'inline' }}>${productDetails.productprice}</span>*/}
                </Card.Title>
                <Card.Text style={styles.productDescription}>{productDetails.item_desc}</Card.Text>
                <Container><Row>
                    <Col className="p-0">
                        <ButtonToolbar aria-label="adding to cart" className="mx-auto">
                            <ButtonGroup className="mr-2">
                                <Button variant="primary" size="sm"
                                    onClick={() => (addToCart('hiking', 1, 'vendor'))}
                                >Add To Cart</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button onClick={() => (changeAddCartQuantity
                            (-1))}>-</Button>
                                <Button variant="outline-primary" disabled>{addToCartCount}</Button>
                                <Button onClick={() => (changeAddCartQuantity
                            (1))}>+</Button>
                            </ButtonGroup>
                            <div className="mx-auto align-middle">${(productDetails.item_price_each * 100) * addToCartCount / 100}</div>
                        </ButtonToolbar>

                    </Col>
                </Row></Container>
            </Card.Body>
        </Card>
    );
};

export default ProductCard;
