import React from 'react'; 
import { InlineIcon } from '@iconify/react';
import starIcon from '@iconify/icons-mdi/star';
import MainMap from './MainMap';
import { Link } from 'react-router-dom';

function HomePage(props) {
    //var sampleProductImage = require('../productimage.jpg');
    const initialScopeProductRecommendations = [
        {
            item_uuid: '9b1273e4-6a43-4500-b3d7-1cc63a67848a',
            item_name: 'Sunglasses',
            item_price_each: 16.99,
            vendor_name: 'Nikki',
            vendor_uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9',
            item_rating: 3
        },
        {
            item_uuid: '3dbb62fb-628a-43c7-a59b-35b9f0915708',
            item_name: 'Beach Towel',
            item_price_each: 22.99,
            vendor_name: 'Nikki',
            vendor_uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9',
            item_rating: 5
        },
        {
            item_uuid: '7dee6813-f0fe-4ba1-9cc0-1d472abd1bdf',
            item_name: 'Nail polish',
            item_price_each: 14.99,
            vendor_name: 'Nikki',
            vendor_uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9',
            item_rating: 5
        },
        {
            item_uuid: '9b1273e4-6a43-4500-b3d7-1cc63a67848a',
            item_name: 'Sunglasses',
            item_price_each: 16.99,
            vendor_name: 'Nikki',
            vendor_uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9',
            item_rating: 3
        },
        {
            item_uuid: '9b02f5c7-64f7-4289-9e3e-a7559a57f935',
            item_name: 'Suncream',
            item_price_each: 29.99,
            vendor_name: 'Devin',
            vendor_uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65',
            item_rating: 3
        },
        {
            item_uuid: 'fb4571ca-cec3-4800-898a-74d68a814db7',
            item_name: 'Beach chairs',
            item_price_each: 36.99,
            vendor_name: 'Devin',
            vendor_uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65',
            item_rating: 4
        }
    ];

    return (

        <div className="content">
            {/*<h2 className="sectionTitle">Vendors near you</h2>*/}
            <MainMap></MainMap>

            <h2 className="sectionTitle">Products frequently bought near you</h2>
            <div className="horizontalScrollContainer ml-2">
                {initialScopeProductRecommendations.map( (product, i)  => (
                    <Link to={'/vendor/' + product.vendor_uuid + "/" + product.item_uuid} key={i}>
                        <div className="pr-2" key={product.item_uuid}>
                            <div className="home-freq-img-landscape"><img className="fill-parent" src={process.env.PUBLIC_URL + product.item_uuid + ".jpg"} alt="product" /></div>
                            <div className="text-body font-weight-bold">{product.item_name}</div>
                            <p className="text-body font-weight-light">{product.vendor_name}'s Store</p>
                            <div className="d-flex">
                                <div className="d-inline mr-2">
                                    {Array.apply(null, { length: Math.floor(product.item_rating) }).map((e, i) => (
                                        <InlineIcon width="0.7em" icon={starIcon} color="gold" key={"gold-" + i} />
                                    ))}
                                    {Array.apply(null, { length: 5 - Math.floor(product.item_rating) }).map((e, i) => (
                                        <InlineIcon width="0.7em" icon={starIcon} color="gray" key={"gray-" + i} />
                                    ))}
                                </div>
                                <div className="d-inline text-primary">
                                    ${product.item_price_each}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>

    );
}

export default HomePage;


/*
backup
const initialScopeProductRecommendations = [
        {
            id: '1234-5678',
            name: 'Allen Edmonds Brogues',
            price: '$50',
            vendorName: 'Nikki Howe',
            vendorId: 2,
            stars: 3
        },
        {
            id: '1234-5679',
            name: 'Allen Edmonds Brogues',
            price: '$50',
            vendorName: 'Nikki Howe',
            vendorId: 2,
            stars: 3
        },
        {
            id: '1234-5680',
            name: 'Allen Edmonds Brogues',
            price: '$50',
            vendorName: 'Nikki Howe',
            vendorId: 2,
            stars: 3
        },
        {
            id: '1234-5681',
            name: 'Allen Edmonds Brogues',
            price: '$50',
            vendorName: 'Nikki Howe',
            vendorId: 2,
            stars: 3
        },
        {
            id: '1234-5682',
            name: 'Allen Edmonds Brogues',
            price: '$50',
            vendorName: 'Nikki Howe',
            vendorId: 2,
            stars: 3
        }
    ];
    */