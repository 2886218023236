import React, { useState, useEffect } from 'react';
import MapGL, { GeolocateControl /*, Source, Layer, Marker*/ } from 'react-map-gl';
import VendorMarker from './VendorMarker';
import { InlineIcon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import SearchAhead from './SearchAhead';
import { ggSearch } from '../utils/DataUtils'
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify'

//required redux importas
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setVendors } from '../redux/vendorSlice'
import ggio from '../utils/io-util'

const TOKEN = 'pk.eyJ1IjoiZGVjaWRhYmxlIiwiYSI6ImNrMmE4dGUyZzBqbmQzcG84NGk2YmthZ3kifQ.bvEHMuqFqfITOTeXuf1obw';

const geolocateStyle2 = {
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 10
};

const MainMap = () => {
  //var geoViewport = require('@mapbox/geo-viewport');

  const [viewport, setViewPort] = useState({
    width: "100%",
    height: "100%", //400,
    latitude: -33.828,
    longitude: 151.199594,
    zoom: 15
  });

  //const [vendorGeoJsonOld, setVendorGeoJson] = useGlobal('vendorGeoJson');
  const [searchVal, setSearchVal] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const dispatch = useDispatch()
  const vendorGeoJson = useSelector(state => state.vendorSlice.vendorGeoJson, shallowEqual )
  //const bus = useBus();

  const _onGeolocate = p => {
    console.log(p);
  };

  const _onViewportChange = viewport => {
    //const b = geoViewport.bounds([viewport.longitude, viewport.latitude], viewport.zoom, [viewport.width, viewport.height]);
    //b is [0,1,2,3] of WSEN
    //TODO update this to selectively fetch new data
    
    /*bus.publish({
      type: 'map.move',
      payload: 'hellobilly'
    });*/

    //PubSub.publish('topic1', 'hello world');
    
    setViewPort({ ...viewport, transitionDuration: 0 });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await ggio.ggMapDataGeoJson(100,100,100,100,true); //placeholders for latitude / longitude values
        dispatch(setVendors({vendorGeoJson: cloneDeep(res)}))
      } catch (error) {
        console.error('MainMap useEffect: error with receiving data');
        toast.error('Unable to get local vendors', {
          position: 'bottom-center',
          autoClose: 2500
        })
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderMarkers = vends => {
    if (!vends || !vends.features) {
      return false;
    }

    const m = vends.features.map((v, i) => (
      <VendorMarker
        latitude={v.geometry.coordinates[1]}
        longitude={v.geometry.coordinates[0]}
        imgsrc={v.properties.uuid + '-circle-50'} key={i}
        vendorobject={v}
      />
    ));
    return (m);
  };


  //data formats for vendor icons
  /*const pointLayer = {
    type: 'symbol',
    id: 'gimmegimmevendors',
    layout: {
      'icon-image': 'marker-15',
      'icon-allow-overlap': true,
    }
  };*/

  const doSearch = e => {
    const sval = e.target.value;
    setSearchVal(sval);

    //check to see if search is >= 2 chars
    if (sval.length < 2) {
      setSearchResults(null);
      return;
    }

    setSearchLoading(true);
    const sres = ggSearch({ query: sval, data: vendorGeoJson });
    console.log('search is ' + searchLoading)
    sres.then((r) => {
      //update the search value
      setSearchLoading(false);
      setSearchResults(r);
    }, (err) => {
      setSearchLoading(false);
      console.log('found an error');
      console.log(err);
    });
  }

  const _renderSearchBox2 = () => {
    const r = (
      <>
        <div className="d-flex justify-content-center mapsearchbox">
          <div className="input-group w-75 pt-6 mt-6">
            <input className="form-control py-2 border-right-0 border" type="search" placeholder="Search local vendors" value={searchVal} onChange={(e) => { doSearch(e) }} id="example-search-input" />
            <span className="input-group-append">
              <div className="input-group-text"><InlineIcon icon={magnifyIcon} /></div>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <SearchAhead className="w-75 rounded" results={searchResults} loading={searchLoading} searchVal={searchVal} />
        </div>
      </>
    )
    return r;
  }

  return (
    <div style={{ 
      margin: '0 auto',
      height: '55vh'
      }}>
      <MapGL
        {...viewport}
        mapboxApiAccessToken={TOKEN}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={_onViewportChange}
      >
        {_renderMarkers(vendorGeoJson)}
        {_renderSearchBox2()}

        <GeolocateControl
          style={geolocateStyle2}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          fitBoundsOptions={{ maxZoom: 12 }}
          onGeolocate={_onGeolocate}
        />
      </MapGL>
    </div>
  )
}

export default MainMap;
