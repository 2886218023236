import React, { useEffect, useState, useRef } from 'react';
/*import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import cloneDeep from 'lodash/cloneDeep';
import pullAt from 'lodash/pullAt';*/
import { useParams } from 'react-router-dom';

import { getCartTotal, VendorCards } from "./CartPage.js";
import TrackingMap from './TrackingMap.js';
import axios from 'axios';

/* 

commenting out the sample status responses



const sampleStatusResponseWait = {
    currentStatusText: "Waiting for acceptance from vendor...",
    currentStatusCode: "WAITVENDOR",
    currentVendorLocations: [
        {
            lat: -33.827955,
            lon: 151.201173,
            uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9' //nikki
        },
        {
            lat: -33.831246,
            lon: 151.199594,
            uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65' //devin
        }
    ]
};

const sampleStatusResponsePrep = {
    currentStatusText: "Preparing order...",
    currentStatusCode: "PREP",
    currentVendorLocations: [
        {
            lat: -33.827955,
            lon: 151.201173,
            uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9' //nikki
        },
        {
            lat: -33.831246,
            lon: 151.199594,
            uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65' //devin
        }
    ]
};

const sampleStatusResponseEnroute = {
    currentStatusText: "En route to you...",
    currentStatusCode: "ENROUTE",
    currentVendorLocations: [
        {
            lat: -33.827955,
            lon: 151.201173,
            uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9' //nikki
        },
        {
            lat: -33.831246,
            lon: 151.199594,
            uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65' //devin
        }
    ]
};
*/

//this is the one being used for samples now
const sampleStatusResponseBlank = {
    currentStatusText: "Connecting to server...",
    currentStatusCode: "SERVCONNECT",
    currentVendorLocations: []
};

//custom hook to set up the seconds interfal
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  /**
   * a helper function to get data from the ggapi
   * @param {object} params 
   * 
   * allowed values for the request_type: "create_order","get_order_details","get_vendor_locations",
   */
const getDataHelper = async (params) => {
    //'https://57uyyp8av3.execute-api.ap-southeast-2.amazonaws.com/Prod/gg', 
    console.log('getDataHelper, params is');
    console.log(params);
    try {
        const result = await axios.post('https://57uyyp8av3.execute-api.ap-southeast-2.amazonaws.com/Prod/gg', params);
        console.log('getDataHelper: received data, logging');
        console.log(result);
        //TODO add code to validate the result received
        if (result.status === 200) { //status OK
            return result;
        } else {
            console.log("getDataHelper: received data but not 200");
            console.log(result);
            return result;
        }
    } catch (error) {
        //setDataError(true);
        console.error('getDataHelper: error while receiving data');
        console.error(error);
        return error;
    }
};

/**
 * Tracking Component
 *  props expects an orderNumber string
 */
const Tracking = (props) => {

    //const [scart] = useGlobal('cart'); //removed the function reference for saving
    const [orderCart, setOrderCart] = useState();
    const [counter, setCounter] = useState(11);
    const [counterRunning, setCounterRunning] = useState(false);
    //const [vendorLocations, setVendorLocations] = useState();
    const [dataLoading, setDataLoading] = useState(false);
    //const [secondsRemaining, setSecondsRemaining] = useState(5);
    const [ cartTotalItems, setCartTotalItems ] = useState();
    const [ cartTotalCost, setCartTotalCost ] = useState();
    //const h = useHistory();
    const [ currentStatus, setCurrentStatus ] = useState(sampleStatusResponseBlank);

    let { orderid } = useParams();

    //let secondsRemaining = 5;

    /**
     * use this in case the shopping cart is not in the global state
     */
    const getOrderContents = async (orderNumber=false, getVendorLocations=true) => {
        //do stuff
        //request_type: "create_order","get_order_details","get_vendor_locations",
        var orderItems;

        //check to see if it's cached first
        if (orderNumber) {
            orderItems = JSON.parse(localStorage.getItem(orderNumber.concat('#items'))); 
            if (orderItems) { console.log ('found orderItems in localStorage'); console.log(orderItems); }
        }

        if (!orderItems) {
            console.log('orderItems not in cache, requesting from server');
            const useOrderId = (orderNumber) ? orderNumber : 'a96ac385-0162-4a5c-b660-d4114c371614';
            const req = {
                request_type: "get_order_details",
                order_id: useOrderId
            };
            const r = await getDataHelper(req);
            orderItems = r.data.order_json;
            localStorage.setItem(useOrderId.concat('#items'), JSON.stringify(orderItems) );
        }

        console.log('setting the order cart to be this:');
        console.log(orderItems);
        setOrderCart(orderItems);
        const [i,c] = getCartTotal(orderItems);
        setCartTotalItems(i);
        setCartTotalCost(c);

        if (getVendorLocations) {
            await getLocationData();
        }
    };

    /**
     * request an update for the order
     * return back the current locations of the vendors
     * TODO pull the vendors from the actual order ojbject in the database
     */
    const getLocationData = async () => {
        setCounterRunning(false);
        setDataLoading(true);
        //load the data from the API
        const req = {
            request_type: "get_vendor_locations",
            vendors: ['59e3ae5c-568c-4e73-88c5-83943933e6d9', '08dba071-1439-4e15-8ebb-04e5698c1f65'] //todo replace
        };
        const r = await getDataHelper(req);
        //console.log(r);     
        setCurrentStatus(r.data);
        setDataLoading(false);
        setCounterRunning(true);
    };


    useEffect(() => {
        if (orderid) {
            getOrderContents(orderid);
        } else {
            //try and see if there is something in localStorage
            var lsOrderNumber;
            try {
                let lsval = localStorage.getItem('most_recent_confirmed_order_id'); //should be a string
                console.log('most recent order is saved in localstorage is ' + lsval);
                lsOrderNumber = lsval;
            } catch(e) {
                console.error('error while parsing localstorage');
                console.error(e);
            }

            if (lsOrderNumber) {
                getOrderContents(lsOrderNumber);
            } else { 
                getOrderContents(); 
            }
        }
        //getLocationData();
        //setCounterRunning(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderid]);

    useEffect(() => {

    }, [orderCart]);

    useInterval(() => {
        //do stuff
        setCounter(counter + 1);
        console.log('tick ' + counter + ' : ' + (counter % 10));
        if (counter % 10 === 0) {
            console.log('bong');
            setCounterRunning(false);
            getLocationData();
        }
    }, counterRunning ? 1000: null);

    const statusUpdateText = () => {
        if (dataLoading) {
            return (<p>Next update: Loading data, please wait...</p>)
        }
        const i = (counter % 10);

        if (i === 0) {
            return (<p>Next update: connecting to server</p>)
        } else {
            return (
                <p>Next update: in {11-i} seconds</p>)
        }
    };
    
    //if (!orderid) {

    return (

        <div className="content">
            <div className="p-2 border-bottom border-dark"> {/* sticky top removed  fixed-below-menu */}
                <div className="d-flex">
                    <span className="font-weight-bolder">Order Details</span>
                    <span className="flex-grow-1">&nbsp;</span>
                    <span className="font-weight-bolder">${cartTotalCost}</span>
                </div>
                <div className="d-flex">
                    <span className="text-muted">{cartTotalItems}&nbsp;items</span>
                    <span className="flex-grow-1">&nbsp;</span>
                    <span className="text-muted">Total</span>
                </div>
            </div>
            <h2 className="sectionTitle">Status</h2>
            <p>Current status: {currentStatus.currentStatusText}</p>
            {statusUpdateText()}
            <h2 className="sectionTitle">Tracking</h2>
            <TrackingMap vendorLocations={currentStatus.currentVendorLocations}/>
            <h2 className="sectionTitle">Order Contents</h2>
            <VendorCards vendors={orderCart} isTracking="true" />
        </div>
    );
};

export default Tracking;