//the shopping cart slice for state

/*
cart object shape:
[
    {
        items: [
            {
                item_add_dttm: 1589678572018,
                item_image: "linktoimage",
                item_name: "Bluetooth speaker",
                item_price_each: 36.99,
                item_qty: 1,
                item_uuid: "lkjasfdlkjasdfl"
            },
            {},
            {}
        ],
        vendor_image: "nothing",
        vendor_name: "Devin",
        vendor_uuid: "lkjasdfkljafsdklj"
    },
    {
        //another vendor here etc
    }
]
*/

import { createSlice } from '@reduxjs/toolkit'
import pullAt from 'lodash/pullAt';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: { //TODO impelement
        /**
         * Add an item into the cart
         * @param {Object} state 
         * @param {Object} action - { vendor_name, vendor_image, vendor_uuid, item_uuid, item_name, item_price_each, item_image, item_qty, item_add_dttm }
         */
        addItem(state, action) { //add an item to the cart, given the full item details given the action { vendor_name, vendor_image, vendor_uuid, item_uuid, item_name, item_price_each, item_image, item_qty, item_add_dttm }
            let existingVendor = state.find((v) => (action.payload.vendor_uuid === v.vendor_uuid));

            if (!existingVendor) { //new vendor, need to add the vendor first
                let newVendor = {
                    items: [],
                    vendor_name: action.payload.vendor_name,
                    vendor_image: action.payload.vendor_image,
                    vendor_uuid: action.payload.vendor_uuid
                }
                state.push(newVendor);
                existingVendor = newVendor;
            }

            let itemRef = existingVendor.items.find((i) => (action.payload.item_uuid === i.item_uuid));

            if (!itemRef) { //have not yet added this item
                //TODO validate cart quantity not exceeded
                let newItem = {

                    item_uuid: action.payload.item_uuid,
                    item_name: action.payload.item_name,
                    item_price_each: action.payload.item_price_each,
                    item_image: 'linktoimage',
                    item_qty: action.payload.item_qty, //what is the cart qty? forcing a quantity of 1 for now
                    item_add_dttm: action.payload.item_add_dttm
                }
                existingVendor.items.push(newItem);
            } else { //have already added the item, time to update the count
                itemRef.item_qty = itemRef.item_qty + action.payload.item_qty; //todo: validate that it's not above max
            }
        },
        removeItem(state, action) {  //remove an item from the cart, given the item uuid  in a prop { item_uuid: 'xxxx' }
            let newItems = [];
            let removeVendor = -1;

            state.forEach((vendor, idx) => {
                newItems = vendor.items.filter(i => i.item_uuid !== action.payload.item_uuid);
                vendor.items = newItems;
                //if we do not have any more items, we need to remove the vendor
                if (newItems.length === 0) {
                    removeVendor = idx;
                }
            });

            if (removeVendor > -1) { //remove the vendor from the array if there is nothing left in their list
                pullAt(state, removeVendor);
            }
        },
        adjustItemQty(state, action) { //add a value (positive or negative) to the item quantity { item_uuid: 'xxxx', quantity_adjust: -1 or 1 }
            state.forEach((vendor) => {
                vendor.items.forEach((item) => {
                    if (item.item_uuid === action.payload.item_uuid) { //we use globally unique item uuids
                        //adjust the quantity
                        if (item.item_qty + action.payload.quantity_adjust > 0) {
                            item.item_qty = item.item_qty + action.payload.quantity_adjust; //what if we have 0? then we should probably remove the item
                        }
                    }
                })
            })
        },
        clearCart(state, action) { ////remove everything from the cart and start over
            state = [];
        }
    }
});

const { actions, reducer } = cartSlice;
export const { addItem, removeItem, adjustItemQty, clearCart } = actions;
export default reducer;




//old implementations

/*

//takes the uuid of the item, the uuid of the vendor, the current cart
//returns an updated cart
function removeItemFromCart(curcart, itmuuid) {
    let newitems = [];
    let newcart = cloneDeep(curcart);
    let removevendor = -1;

    //go through the vendors
    newcart.forEach((vendor, idx) => {
        newitems = vendor.items.filter(i => i.item_uuid !== itmuuid)
        vendor.items = newitems;
        //if we do not have any more items, we need to remove the vendor
        if (newitems.length === 0) {
            removevendor = idx;
        }
    });

    if (removevendor > -1) {
        pullAt(newcart, removevendor);
    }

    return newcart;
}



//change the quantity of the item in cart, returns a new shopping cart object (which should then be set as global)
function changeItemQtyInCart(curcart, itmuuid, qty) {
    let newcart = cloneDeep(curcart);
    newcart.forEach((vendor) => {
        vendor.items.forEach((item) => {
            if (item.item_uuid === itmuuid) { //we use globally unique item uuids
                //adjust the quantity
                if (item.item_qty + qty > 0) {
                    item.item_qty = item.item_qty + qty;
                }
            }
        })
    })
    return newcart;
};


    const addToCart = (prd, qty, vnd) => {
        let newCartState = Array.from(cartState);
        //first see if the vendor is in the cart
        //let existingVendor = newCartState.find((v) => (productDetails.vendor_uuid === v.vendor_uuid));
        let existingVendor = newCartState.find((v) => (productVendor.properties.uuid === v.vendor_uuid));

        if (!existingVendor) { //new vendor, need to add the vendor first
            let newVendor = {
                items: [],
                vendor_name: productVendor.properties.name,
                vendor_image: productVendor.properties.iconfull,
                vendor_uuid: productVendor.properties.uuid
            }
            newCartState.push(newVendor);
            existingVendor = newVendor;
        }

        let itemRef = existingVendor.items.find((i) => (productDetails.item_uuid === i.item_uuid));

        if (!itemRef) { //have not yet added this item
            //todo validate cart quantity not exceeded
            let newItem = {

                item_uuid: productDetails.item_uuid,
                item_name: productDetails.item_name,
                item_price_each: productDetails.item_price_each,
                item_image: 'linktoimage',
                item_qty: addToCartCount, //what is the cart qty?
                item_add_dttm: Date.now()
            }
            existingVendor.items.push(newItem);
        } else { //have already added the item, time to update the count

            itemRef.item_qty = itemRef.item_qty + addToCartCount; //todo: validate that it's not above max
        }

        setCartState(newCartState);
        console.log(newCartState);
    }



*/