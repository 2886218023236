import { createSlice } from '@reduxjs/toolkit'

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        phoneNumber: '',
        phoneNumberSet: false,
        customerId: '',
        orderId: '',
        orderIdSet: false,
        orderAmountCents: 0,
        orderAmountSet: false,
        customerIdSet: false,
        phoneChallengeId: '',
        userToken: '',
        createPaymentComponent: false,
        slidingMarginLeft: '0vw',
        curWindow:0,
        showScreens: {
            confirmLocation: false,
            capturePayment: false
        }
    },
    reducers: { 
        setPhoneNumber(state,action) { //action.payload
            state.phoneNumber = action.payload.phoneNumber;
            state.phoneNumberSet = true;
        },
        setCustomerId(state,action) {
            state.customerId = action.payload.customerId;
            state.customerIdSet = true;
        },
        setOrderId(state, action) {
            state.orderId = action.payload.orderId;
            state.orderIdSet = true;
        },
        setOrderAmount(state, action) {
            state.orderAmountCents = action.payload.orderAmountCents;
            state.orderAmountSet = true;
        },
        moveWindowForward(state,action) {
            state.curWindow = state.curWindow-1;
            state.slidingMarginLeft = (state.curWindow * 100).toString() + 'vw'
        },
        moveWindowBack(state,action) {
            state.curWindow = state.curWindow+1;
            state.slidingMarginLeft = (state.curWindow * 100).toString() + 'vw'            
        },
        setPhoneChallengeId(state,action){
            state.phoneChallengeId = action.payload.phoneChallengeId;
        },
        setUserToken(state,action) { 
            state.userToken = action.payload.userToken;
        },
        showLocation(state,action) { state.showScreens.confirmLocation = true },
        showPayment(state,action) { state.showScreens.capturePayment = true }
    }
});

const { actions, reducer } = checkoutSlice;
export const { setOrderId, setPhoneNumber, setCustomerId, setOrderAmount, moveWindowBack, moveWindowForward, setPhoneChallengeId, setUserToken, showLocation, showPayment } = actions; 
export default reducer;