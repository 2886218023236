import { combineReducers } from '@reduxjs/toolkit'
import cartSliceReducer from './cartSlice'
import uiSliceReducer from './uiSlice'
import vendorSliceReducer from './vendorSlice'
import checkoutSliceReducer from './checkoutSlice'

const rootReducer = combineReducers({
    cartSlice: cartSliceReducer,
    uiSlice: uiSliceReducer,
    vendorSlice: vendorSliceReducer,
    checkoutSlice: checkoutSliceReducer
})

export default rootReducer