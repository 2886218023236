
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopBar from './components/TopBar';
import HomePage from './components/HomePage';
import VendorPage from './components/VendorPage';
import GoogleFontLoader from 'react-google-font-loader';
import Footer from './components/Footer';
import CartPage from './components/CartPage';
import Checkout from './components/CheckoutLoadable';
import Tracking from './components/Tracking';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { toggleSlideMenuOpen } from './redux/uiSlice'

function App() {

  //const [uidetailsold, setuidetails] = useGlobal('ui');
  const uidetails = useSelector(state => state.uiSlice)
  const dispatch = useDispatch()

  const slidemenustyle = {
    width: uidetails.slidemenuopen ? '88vw' : 0
  }

  const _handleSidebarClick = () => {
    dispatch(toggleSlideMenuOpen())
  }


  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Playfair Display',
            weights: [400, 700],
          }
        ]}
      />

      <TopBar />

      <div className="slidemenu" style={slidemenustyle}>
        <Link to="/" onClick={() => _handleSidebarClick()} >Home</Link>
        <Link to="/cart" onClick={() => _handleSidebarClick()} >Cart</Link>
        <Link to="/track" onClick={() => _handleSidebarClick()} >Track</Link>
        <Link to="/faq" onClick={() => _handleSidebarClick()} >FAQ</Link>
      </div>

      <Switch>
        <Route path="/vendor/:v/:i?">
          <VendorPage />
        </Route>

        <Route path="/cart">
          <CartPage />
        </Route>

        <Route path="/checkout">
          <Checkout />
        </Route>

        <Route path="/track/:orderid?">
          <Tracking />
        </Route>

        <Route exact path="/">
          <HomePage></HomePage>
        </Route>

      </Switch>

      <Footer />


    </>
  );
}

export default App;
