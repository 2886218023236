import React from 'react';

//social media icons
import { InlineIcon } from '@iconify/react';
import facebookIcon from '@iconify/icons-mdi/facebook';
import twitterIcon from '@iconify/icons-mdi/twitter';
import pinterestIcon from '@iconify/icons-mdi/pinterest';
import linkedinIcon from '@iconify/icons-mdi/linkedin';

function Footer(props) {
    return (
        <div className="footer">
            <h3>Help</h3>
            <ul>
                <li><a href="tel:+61437198926">0437 198 926</a></li>
                <li><a href="mailto:info@gimmegimme.com.au">info@gimmegimme.com.au</a></li>
                <li>Returns Policy</li>
                <li>FAQ / Contact Us</li>
            </ul>

            <h3>Shop</h3>
            <ul>
                <li>Shops near me</li>
                <li>Search for a product</li>
            </ul>

            <h3>Partner With Us</h3>
            <ul>
                <li>How to join</li>
                <li>Join now</li>
                <li>Partner portal</li>
            </ul>

            <h3>Company</h3>
            <ul>
                <li>Our story</li>
                <li>Contact details</li>
                <li>Our commitments</li>
            </ul>

            <h3>Follow Gimme Gimme</h3>
            <ul>
                <li>Exclusive offers and advance info on exciting changes coming soon.</li>
                <li className="socialicons">
                    <InlineIcon icon={twitterIcon} />
                    <InlineIcon icon={facebookIcon} />
                    <InlineIcon icon={pinterestIcon} />
                    <InlineIcon icon={linkedinIcon} />
                </li>
            </ul>
            <ul>
                <li>&copy; 2020 Gimme Gimme Technologies Pty. All Rights Reserved. Terms, Privacy, and Accessibility</li>
            </ul>

        </div>
    );
}

export default Footer;