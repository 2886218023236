import React from 'react';
import ProductCard from './ProductCard';
import CardDeck from 'react-bootstrap/CardDeck';
import { useParams, useHistory } from 'react-router-dom';
import without from 'lodash/without';
import { useSelector } from 'react-redux'

function VendorPage(props) {

    //const [vendorGeoJson, setVendorGeoJson] = useGlobal('vendorGeoJson');
    const vendorGeoJson  = useSelector(state => state.vendorSlice.vendorGeoJson )

    const styles = {
        vendorBackground: {
            padding: '0',
            margin: '0',
            //height: '60px',
            width: '100%',
            //boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 24px 0px',
            //display: 'flex',
            //justifyContent: 'space-between',
            //alignItems: 'center',
            paddingLeft: 0,//'16px',
            paddingRight: 0,//'16px',
            backgroundColor: 'black',
            color: 'white',
            //textAlign: 'center',
            paddingTop: '10px'
        },
        vendorContentBox: {
            padding: '0',
            margin: '0',
            width: '100%',
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '10px',
            marginTop: '40px',
            //margin: 'auto'  - collision with other key which was margin 0, not sure which to use 
        },
        vendorImage: {
            color: 'black',
            marginTop: '-50px',
            paddingBottom: '20px',
            position: 'relative',
            marginLeft: 'calc(50vw - 50px)'
        },
        vendorImageDiv: {
            //backgroundColor: 'blue',
            position: 'relative',
            margin: 0,
            padding: 0,
            width: '100vw'
        }
    };

    let routeParams = useParams();
    let history = useHistory();


    const curVendor = routeParams.v && vendorGeoJson.features && vendorGeoJson.features.find(f => f.properties.uuid === routeParams.v);
    //console.log(curVendor);
    if (!curVendor) { //do the validation of the vendor uuid here
        alert('invalid vendor uuid');
        history.push("/");
    }
    //now we know curVendor is a feature in geojson format

    let productDetails = curVendor.properties.items
    const focusItemProp = routeParams.i;
    let focusItem = null;

    if (focusItemProp) {
        focusItem = productDetails.find(i => i.item_uuid === focusItemProp);
        if (!focusItem) {
            alert('invalid item');
            history.push("/");
        }
        productDetails = without(productDetails, focusItem);

    }

    return (
        <div className="content">
            <div style={styles.vendorBackground}>
                {/*<h2>{curVendor.properties.name}'s Store</h2>*/}
                <span>&nbsp;</span>
                <div style={styles.vendorContentBox}>
                    <div style={styles.vendorImageDiv}>
                        <img style={styles.vendorImage} src={process.env.PUBLIC_URL + curVendor.properties.uuid + '-circle-100.png'} alt="vendor" />
                    </div>

                    {focusItem &&
                        <>
                            <CardDeck>
                                <ProductCard vendor={curVendor} product={focusItem} key="focus"></ProductCard>
                            </CardDeck>
                            <p className="m-2">More from this vendor</p>
                        </>
                    }
                    {/* TODO - if a user has selected a specific product, start with that one and put "other products from this vendor" below it. Otherwise just display */}
                    <CardDeck>
                        {productDetails.map((p, i) => (
                            <ProductCard vendor={curVendor} product={p} key={i} />
                        ))}
                    </CardDeck>
                </div>
            </div >

        </div >
    );
}

export default VendorPage;
