import React, { useState } from 'react';
import MapGL, { GeolocateControl } from 'react-map-gl';
import VendorMarker from './VendorMarker';
/*import { InlineIcon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import SearchAhead from './SearchAhead';
import { ggSearch } from '../utils/DataUtils'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';*/

const TOKEN = 'pk.eyJ1IjoiZGVjaWRhYmxlIiwiYSI6ImNrMmE4dGUyZzBqbmQzcG84NGk2YmthZ3kifQ.bvEHMuqFqfITOTeXuf1obw';


const geolocateStyle2 = {
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 10
};

const TrackingMap = (props) => {

    //var geoViewport = require('@mapbox/geo-viewport');
    
    const [viewport, setViewPort] = useState({
      width: "100%",
      height: 250,
      latitude: -33.828, //change to user location at time of order
      longitude: 151.199594,
      zoom: 15 //calculate to get the other vendors in there too
    });

    const _onGeolocate = p => {
      console.log(p);
    };
  
    const _onViewportChange = viewport => {
      //const b = geoViewport.bounds([viewport.longitude, viewport.latitude], viewport.zoom, [viewport.width, viewport.height]);
      //b is [0,1,2,3] of WSEN
      //TODO update this to selectively fetch new data
      setViewPort({ ...viewport, transitionDuration: 0 })
    };
  
    const _renderMarkers = vends => {
      if (!vends) { 
        console.log('nothing in vends');
        return null; }
  
      const m = vends.map((v, i) => (
        <VendorMarker
          latitude={v.lat}
          longitude={v.lon}
          imgsrc={v.uuid + '-circle-50'} key={i}
        />
      ));
      return (m);
    };
  
    return (
      <div style={{ margin: '0 auto' }}>
        <MapGL
          {...viewport}
          mapboxApiAccessToken={TOKEN}
          mapStyle="mapbox://styles/mapbox/dark-v9"
          onViewportChange={_onViewportChange}
        >
          {_renderMarkers(props.vendorLocations)}
  
          <GeolocateControl
            style={geolocateStyle2}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            fitBoundsOptions={{maxZoom: 12}}
            onGeolocate={_onGeolocate}
          />
        </MapGL>
      </div>
    )
  }
  
  export default TrackingMap;