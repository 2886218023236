import React from 'react';
import HamburgerButton from './HamburgerButton';
import { Icon } from '@iconify/react';
import shoppingCartOutline from '@iconify/icons-ant-design/shopping-cart-outline';
import GoogleFontLoader from 'react-google-font-loader';
import { Link } from 'react-router-dom';
//import { isAbsolute } from 'path';

//required redux importas
import { useSelector } from 'react-redux'

function TopBar(props) {
    const styles = {
        topBar: {
            padding: '0',
            margin: '0',
            height: '60px',
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 24px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            position: 'fixed',
            top: '0',
            zIndex: '9999',
            backgroundColor: 'white'
        },
        topBarTitle: {
            marginBottom: '0',
            fontSize: '24px',
            fontFamily: 'Playfair Display, serif'
        },
        cartActive: {
            position: 'absolute',
            top: '0px',
            right: '0px'
        }
    };

    const curcart = useSelector(state => state.cartSlice)

    return (
        <>
            <GoogleFontLoader
                fonts={[
                    {
                        font: 'Playfair Display',
                        weights: [400],
                    }
                ]}
            />
            <div style={styles.topBar} >
                <HamburgerButton />
                <Link to="/" className="link-inherit"><h1 style={styles.topBarTitle}>GimmeGimme</h1></Link>
                <div style={{position: 'relative'}}>
                    <Link to="/cart" className="link-inherit"><Icon icon={shoppingCartOutline} height='30px' /></Link>
                    {(curcart.length > 0) && 
                    <svg width="10" height="10" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styles.cartActive}>
                        <circle cx="5" cy="5" r="5" fill="red" />
                    </svg>}
                </div>
            </div>
        </>
    );
}

export default TopBar;
