import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "react-router-dom";
import SCT from './components/SCT.js';
import { Provider } from 'react-redux'
import store from './redux/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

//import 'normalize.css';

//set initial state
/*setGlobal({
  ui: {
    currentWindow: 'content-page-main',
    previousWindow: '',
    hamburgerState: 0,
    slidemenuopen: false
  },
  cart: [],
  vendorGeoJson: {}
});*/


const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <ToastContainer />
        <SCT />
        <App />
      </HashRouter>
    </Provider>,
    document.getElementById('reactroot')
  )
}

render()

/* backup cart entry
cart: [
    {
      vendor_uuid: '08dba071-1439-4e15-8ebb-04e5698c1f65',
      vendor_name: 'Devin',
      vendor_image: 'linktoimage',
      items: [
        {
          item_uuid: 'db8c0f35-e1a1-45c9-ace1-e7f172082894',
          item_name: 'iPhone xR',
          item_price_each: 49.99,
          item_image: 'linktoimage',
          item_qty: 1,
          item_add_dttm: 1573876779273
        },
        {
          item_uuid: '5ca7a6e8-d543-46ca-8c14-ef46cfa87866',
          item_name: 'Revlon Red Lipstick',
          item_price_each: 4.99,
          item_image: 'linktoimage',
          item_qty: 1,
          item_add_dttm: 1573876779279
        }
      ]
    },
    {
      vendor_uuid: '59e3ae5c-568c-4e73-88c5-83943933e6d9',
      vendor_name: 'Nikki',
      vendor_image: 'linktoimage',
      items: [
        {
          item_uuid: 'ea6b0fd2-a3a7-48b6-8401-4b4cb072aecb',
          item_name: 'Bathtub',
          item_price_each: 149.99,
          item_image: 'linktoimage',
          item_qty: 1,
          item_add_dttm: 1573876779273
        },
        {
          item_uuid: '5ca8b03ed0a-d976-46f1-952a-158549640861',
          item_name: 'AirPods Fancy Headphones',
          item_price_each: 94.99,
          item_image: 'linktoimage',
          item_qty: 1,
          item_add_dttm: 1573876779279
        }
      ]
    }
  ],

  */



  /* VENDOR GEOJSON CONTENTS


    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "id": "1",
        "geometry": {
          "type": "Point",
          "coordinates": [151.201173, -33.827955]
        },
        "properties": {
          "name": "Nikki",
          "icon": "nikki-circle-50",
          "iconfull": "nikki-circle-100",
          "uuid": "59e3ae5c-568c-4e73-88c5-83943933e6d9",
          "items": [
            {
              "item_uuid": "b9e4baad-4f95-4590-8f84-644adb6e2c38",
              "item_name": "Ice-creams",
              "item_image": "image.jpg",
              "item_price_each": 9.99,
              "item_rating": 4.8,
              "item_qty_max": 10,
              "item_desc": "The best vanilla- chocolate ice-creams in town exactly when you need it."
            },
            {
              "item_uuid": "78cbd291-52b7-4856-bbb8-c40bbb7757c0",
              "item_name": "Slippers",
              "item_image": "image.jpg",
              "item_price_each": 14.99,
              "item_rating": 3,
              "item_qty_max": 10,
              "item_desc": "Good quality slippers,easy to walk and a casual look."
            },
            {
              "item_uuid": "7dee6813-f0fe-4ba1-9cc0-1d472abd1bdf",
              "item_name": "Nail polish",
              "item_image": "image.jpg",
              "item_price_each": 14.99,
              "item_rating": 5,
              "item_qty_max": 2,
              "item_desc": "Nail polish Watermelon is a beautiful and bright creme nail polish perfect for spring and summer"
            },
            {
              "item_uuid": "3dbb62fb-628a-43c7-a59b-35b9f0915708",
              "item_name": "Beach Towel",
              "item_image": "image.jpg",
              "item_price_each": 22.99,
              "item_rating": 5,
              "item_qty_max": 2,
              "item_desc": "Soft to touch,Easy to care,Machine-washable,Material: microfibre."
            },
            {
              "item_uuid": "9b1273e4-6a43-4500-b3d7-1cc63a67848a",
              "item_name": "Sunglasses",
              "item_image": "image.jpg",
              "item_price_each": 16.99,
              "item_rating": 3,
              "item_qty_max": 10,
              "item_desc": "Very trendy and cool,Best Summer glasses."
            }
          ]
        }
      },
      {
        "type": "Feature",
        "id": "2",
        "geometry": {
          "type": "Point",
          "coordinates": [151.199594, -33.831246] //format longitude, latitude
        },
        "properties": {
          "name": "Devin",
          "icon": "devin-circle-50",
          "iconfull": "devin-circle-100",
          "uuid": "08dba071-1439-4e15-8ebb-04e5698c1f65",
          "items": [
            {
              "item_uuid": "9b02f5c7-64f7-4289-9e3e-a7559a57f935",
              "item_name": "Suncream",
              "item_image": "image.jpg",
              "item_price_each": 29.99,
              "item_rating": 4.5,
              "item_qty_max": 5,
              "item_desc": "The best suncream product on a high street hot days,easy to use leaving you with a smooth skin."
            },
            {
              "item_uuid": "c313a072-bcea-4fcf-85a0-41f626ed02be",
              "item_name": "Tent 2 people",
              "item_image": "image.jpg",
              "item_price_each": 29.99,
              "item_rating": 4.3,
              "item_qty_max": 2,
              "item_desc": "2 Person tent,protection when the weather takes a turn.Making this tent perfect for those weekend campers or festival-goers!"
            },
            {
              "item_uuid": "fb4571ca-cec3-4800-898a-74d68a814db7",
              "item_name": "Beach chairs",
              "item_image": "image.jpg",
              "item_price_each": 36.99,
              "item_rating": 4.6,
              "item_qty_max": 4,
              "item_desc": "This deluxe beach chair makes relaxing a breeze. With a lightweight, aluminum-and-steel frame."
            }
          ]
        }
      }
    ]
  
    */