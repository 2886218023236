//interfacing with the server

const ax = require( 'axios' );
const gg_server_address = 'https://57uyyp8av3.execute-api.ap-southeast-2.amazonaws.com/Prod/gg';
//const gg_server_address = "http://127.0.0.1:4000/gg";
//const gg_server_address = (process.env.NODE_ENV && process.env.NODE_ENV == 'test') ? 'http://localhost:4000/gg' : 'https://57uyyp8av3.execute-api.ap-southeast-2.amazonaws.com/Prod/gg';
const gg_debug_value = true;

const gg_debug = (debug_message) => {
    if (gg_debug_value) {
        if (debug_message.length < 175) {
            console.log(debug_message);
        } else {
            console.log(debug_message.slice(0,174) + " [truncated]")
        }
    }
};

const doIo = async (mode, data) => {
    gg_debug('Communicating with server: in io-util.doIo.\nMode: ' + JSON.stringify(mode) + '\nData: ' + JSON.stringify(data));

    try {
        const serverDataParameter = {request_type: mode, ...data};
        const res = await ax.post(gg_server_address, serverDataParameter);
        gg_debug('Response from server: in io-util.doIo.\nMode: ' + mode + '\nData: ' + JSON.stringify(data) + '\nResponse: ' + JSON.stringify(res));
        if (res.status === 200) { //http status ok
            return res.data;
        } else { //http status not ok
            //TODO implement error message handling here
            console.error('Error while communicating with server: in io-util.doIo.\nResult: ' + JSON.stringify(res) + 'Mode: ' + JSON.stringify(mode) + '\nData: ' + JSON.stringify(data));
            throw new Error ('Server-side error (doIo)');
        }
    } catch (e) {
        console.error('Error while communicating with server: in io-util.doIo.\nError: ' + JSON.stringify(e) + 'Mode: ' + JSON.stringify(mode) + '\nData: ' + JSON.stringify(data));
        throw new Error('Error communicating with server (doIo)');
    }
};

/**
 * Send the cart details to the server and return an order number.
 * 
 * @param {Object} shopping_cart - Array of items in the cart per gg standards
 * @returns {string} The order id
 */
const ggInitOrder = async (shopping_cart) => {
    //send the cart details to the server and get back an order number
    try {
        const res = await doIo ('create_order_without_customer', {
            'order_info': shopping_cart
        });
        //this assumes that the request was successful
        //return res.orderid;
        return res;
    } catch (error) {
        //request was not successful, error already logged
        throw error;
    }
};

/**
 * send the customer details to the server and get back an customer number
 * @param {Object} cust_info - Javascript object with customer details
 * @returns {string} The customer id
 */
const ggInitCustomer = async (cust_info) => {
    try {
        const res = await doIo ('create_customer_without_order', {
            'cust_info': cust_info
        });
        //this assumes that the request was successful
        //return res.custid;
        return res;
    } catch (error) {
        //request was not successful, error already logged
        throw error;
    }
};

/**
 * Attaches the order to the customer
 * @param {string} custid - The customer uuid
 * @param {string} orderid - The order uuid
 */
const ggAttachOrderToCustomer = async (custid, orderid) => {
    try {
        await doIo ('attach_order_to_customer', {
            'custid' : custid,
            'orderid' : orderid
        });
        //this assumes that the request was successful
        return true;
    } catch (error) {
        //request was not successful, error already logged
        throw error;
    }
};

/**
 * Connects to API to initialise the stripe payment.
 * @param {string} orderid - Order uuid
 * @param {string} currency_code - Three letter currency code, must be 'aud'
 * @param {number} amt - Amount in cents, must be > 1000
 * @param {Object} data - Optional javascript object to attach to stripe request
 * @returns {string} The secret payment identifier to use with Stripe
 */
const ggInitPayment = async (orderid, currency_code, amt, data) => {
    //initiate payment on a given order id
    //needs currency_code, amount, data

    if (currency_code !== 'aud' || amt < 400) {//error
        throw new Error('Invalid payment parameters')
    }

    try {
        const res = await doIo('init_payment_on_order', {
            'orderid' : orderid,
            'currency' : currency_code,
            'amount' : amt,
            'data' : data
        });
        //return the payment details client secret for confirmation
        //return res.payment_result.client_payment_id;
        return res;
    } catch (error) {
        //request was not successful, error already logged
        throw error;
    }
};

/**
 * Fetch a well formatted GeoJson object for the vendors
 * @param {number} latmin - Latitude for corner of bounding box (min)
 * @param {number} lonmin - Longitude for corder of bounding box (min)
 * @param {number} latmax - Latitude for corner of bounding box (max)
 * @param {number} lonmax - Latitude for corner of bounding box (max)
 * @param {boolean} includeProducts - Include the products or not
 * @returns {Object} The GeoJson object
 */
const ggMapDataGeoJson = async (latmin, lonmin, latmax, lonmax, includeProducts) => {
    //get the default vendor geoJson for now
    //TODO implement properly with bounding box
    try {
        const res = await doIo('get_vendor_geojson');
        //console.log('got the geojson in ggmapdatageojson it is ', res)
        return JSON.parse(res); //it comes through as a string
    } catch (error) {
        //request not successful, error already logged
        //console.error('error in ggmapdatageojson', error)
        throw error;
    }
};

//auth modules
//authRespondToChallenge, //code auth_respond, needs: challengeid and challengeresponse, returns jwt
//authInitAttachPhone, //code auth_init_phone, needs: custid and phnum, returns challengeid

/**
 * Create an auth challenge with a phone number and a customer id   
 * @param {string} custid - The customer uuid
 * @param {string} phnum - The phone number
 * @returns {string} The challenge id
 * @throws {Error} Throws an error if problems
 */
const ggAuthInitPhone = async (custid, phnum) => {
    try {
        const res = await doIo('auth_init_phone', {
            'custid': custid,
            'phnum': phnum
        });
        //returns the challengeid
        return res;
    } catch (error) {
        //request not successful, error already logged
        throw error;
    }
}

/**
 * Respond to the auth challenge
 * @param {string} challengeid - The challengeid (uuid)
 * @param {string} challengeresponse - The challenge response (ie the 5 digit phone code)
 * @returns {string} The jwt token for the customerid
 * @throws {Error} Throws an error if wrong code, too many attempts, or other problems
 */
const ggAuthRespond = async (challengeid, challengeresponse) => {
    try {
        const res = await doIo('auth_respond', {
            'challengeid' : challengeid,
            'challengeresponse' : challengeresponse
        });
        //returns the jwt
        return res;
    } catch (error) {
        //request not successful, error already logged
        throw error;
    }
}

const ggGetOrderContents = async (order_uuid) => {
    //todo implement
    console.log('holding')
};

const ggio = {
    ggInitOrder,
    ggInitCustomer,
    ggAttachOrderToCustomer,
    ggInitPayment,
    ggMapDataGeoJson,
    ggGetOrderContents,
    ggAuthInitPhone,
    ggAuthRespond
};

export default ggio;
