import React, { useState } from 'react'; 
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from 'react-router-dom';

import ggio from '../utils/io-util.js'

//required redux importas
import { useSelector, useDispatch } from 'react-redux'
import { removeItem as removeItemRedux, adjustItemQty } from '../redux/cartSlice'
import { setCustomerId, setOrderId, setOrderAmount } from '../redux/checkoutSlice'

//a card for one single product
const ProductCard = (props) => {
    const dispatch = useDispatch();

    const removeItem = () => {
        dispatch(removeItemRedux({
            item_uuid: props.itm.item_uuid
        }))
    };

    const changeItemQty = (qty) => {
        dispatch(adjustItemQty({
            item_uuid: props.itm.item_uuid,
            quantity_adjust: qty
        }))
    };

    return (
        <Card className="m-2 p-2">
            <div className="clearfix">
                <div className="cart-img-portrait float-left mr-2">
                    <img className="img-fluid float-left" src={process.env.PUBLIC_URL + props.itm.item_uuid + ".jpg"} alt="product" />
                </div>
                <div className="d-flex align-items-start flex-column bd-highlight mb-3 float-left" >
                    <Link to={"/vendor/" + props.v.vendor_uuid + "/" + props.itm.item_uuid}><div className="font-weight-bolder">{props.itm.item_name}</div></Link>
                    <div className="mb-auto">{props.itm.item_qty} @ ${props.itm.item_price_each} = ${(props.itm.item_qty * (props.itm.item_price_each * 100)) / 100}</div>
                    {!props.isTracking && (
                    <div>
                        <ButtonToolbar aria-label="adding to cart" className="mx-auto">
                            <ButtonGroup className="mr-2">
                                <Button variant="dark" size="sm"
                                    onClick={() => (removeItem())}
                                >Remove</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button variant="dark" onClick={() => (changeItemQty(-1))} disabled={(props.itm.item_qty <= 1) ? true : false}>-</Button>
                                <Button variant="outline-dark" disabled>{props.itm.item_qty}</Button>
                                <Button variant="dark" onClick={() => (changeItemQty(1))}>+</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div> )}
                </div>

            </div>
        </Card>
    )
};

const ProductCards = (props) => {
    const itms = props.itms;
    const v = props.v;

    const allitms = itms.map((curitm) => (
        <ProductCard itm={curitm} v={v} key={curitm.item_uuid} isTracking={props.isTracking} />
    ));
    return (
        <CardDeck>
            {allitms}
        </CardDeck>
    )
}

const ShowHideItemButton = (props) => {
    //expects a callbackfunction which is called onClickItems
    const [buttonText, setButtonText] = useState('Show');
    const _onClickHandler = () => {
        setButtonText((buttonText === "Show" ? "Hide" : "Show"));
        props.onClickItems();
    };

    return (
        <Button variant="dark" size="sm" onClick={() => _onClickHandler()}>{buttonText}</Button>
    );
};

//cart items from one individual vendor
const VendorCard = (props) => {

    const curvendor = props.vendor;
    const curvendorvalue = getVendorTotal(curvendor.items);
    const [showProducts, setShowProducts] = useState((props.isTracking) ? false : true);

    const _showItemsToggle = () => {
        setShowProducts(!showProducts);
    };

    return (
        <>
            <div className="mr-3 ml-2"> {/*card for the shop*/}
                <div className="clearfix"> {/*top line of card*/}
                    <div className="cart-img-vendor float-left">
                        <img src={process.env.PUBLIC_URL + curvendor.vendor_uuid + '-circle-100.png'} alt="vendor" className="img-fluid float-left" />
                    </div>
                    <div className="float-left ml-2">
                        {/*<p className="m-0 font-weight-bolder text-uppercase">{curvendor.vendor_name}'s shop</p>*/}
                        <Link to={"/vendor/" + curvendor.vendor_uuid} className="m-0 font-weight-bolder text-uppercase">{curvendor.vendor_name}'s shop</Link>
                        <p className="m-0 text-muted">{curvendor.items.length} 
                            items {props.isTracking && (<ShowHideItemButton onClickItems={() => _showItemsToggle()}/>)}
                        </p>
                    </div>
                    <div className="float-right">
                        <p className="m-0 font-weight-bolder text-right">${curvendorvalue}</p>
                        <p className="m-0 text-muted text-right">Subtotal</p>
                    </div>
                </div>
                {/* products go here */}

                {showProducts && (<ProductCards itms={curvendor.items} v={curvendor} isTracking={props.isTracking}/> )}
            </div>
        </>
    );
}

//cart items from all vendors
const VendorCards = (props) => {
    if (!props.vendors) { return null; }
    const itms = props.vendors;
    const allitms = itms.map((curitm) => (
        <VendorCard vendor={curitm} key={curitm.vendor_uuid} isTracking={props.isTracking} />
    ));
    return allitms;
}

function getVendorTotal(cartitems) {
    if (!cartitems) { return 0; }
    const totalCost = cartitems.reduce((acc, itm) => acc + (itm.item_price_each * 100 * itm.item_qty), 0);
    return totalCost / 100;
}

function getCartTotal(cart) {
    if (!cart) { return [0, 0] }
    const totalCost = cart.reduce((acc, itm) => acc + getVendorTotal(itm.items), 0);
    const totalItems = cart.reduce((acc, itm) => acc + itm.items.length, 0);
    return [totalItems, totalCost];
}

//this is the page reference that should be called
function CartPage(props) {
    const scart = useSelector( (state) => state.cartSlice )
    const dispatch = useDispatch();
    const [ cartTotalItems, cartTotalCost ] = getCartTotal(scart); //todo - need to have a bit at top of cart to show total and a checkout button
    const h = useHistory();
    const gotocheckout = async (props) => { 
        console.log('redirecting to checkout with props equals ' + props);
        if (props) {
            setHideButton(true);
            setButtonText("Loading checkout...");
            try {
                const customerId = await ggio.ggInitCustomer(); //no customer details yet
                const orderId = await ggio.ggInitOrder(scart);
                // eslint-disable-next-line no-unused-vars
                const attachresult = await ggio.ggAttachOrderToCustomer(customerId, orderId);
                dispatch(setCustomerId({"customerId": customerId}));
                dispatch(setOrderId({"orderId": orderId}));
                dispatch(setOrderAmount({"orderAmountCents": cartTotalCost}));
            } catch (error) {
                console.error(error);
            }

        }
        h.push((props) ? "/checkout" : "/");
    }

    const itt = props.isTracking;
    const titleText = (itt) ? "Order Details" : "Shopping Cart";

    const [buttonText, setButtonText] = useState((scart.length > 0) ? "CHECKOUT SECURELY" : "CART EMPTY");
    const [hideButton, setHideButton] = useState((scart.length <= 0));

    return (

        <div style={{ marginTop: "180px" }}>
            <div className="p-2 border-bottom border-dark fixed-below-menu" style= {{ height: "110px"}}> {/* sticky top */}
                <div className="d-flex">
                    <span className="font-weight-bolder">{titleText}</span>
                    <span className="flex-grow-1">&nbsp;</span>
                    <span className="font-weight-bolder">${cartTotalCost}</span>
                </div>
                <div className="d-flex">
                    <span className="text-muted">{cartTotalItems} items</span>
                    <span className="flex-grow-1">&nbsp;</span>
                    <span className="text-muted">Total</span>
                </div>
                {!itt && (
                <div className="pt-2">
                    <Button className="btn-dark" onClick={ () => gotocheckout((scart.length > 0))} block disabled={hideButton}>{buttonText}</Button>
                </div> )}
            </div>
            <VendorCards vendors={scart} isTracking={props.isTracking} />
        </div>
    );
}

export { CartPage as default, getCartTotal, getVendorTotal, VendorCards, VendorCard, ProductCard, ProductCards };


/*
CART DATA STRUCTURE

cart: [
    {
        vendor_uuid: '99685085-ecae-4084-ab08-9cf77a342c28',
        vendor_name: 'Devins Shop',
        vendor_image: 'linktoimage',
        items: [
            {
                item_uuid: 'db8c0f35-e1a1-45c9-ace1-e7f172082894',
                item_name: 'iPhone xR',
                item_price_each: 49.99,
                item_image: 'linktoimage',
                item_qty: 1,
                item_add_dttm: 1573876779273
            },
            {
                item_uuid: '5ca7a6e8-d543-46ca-8c14-ef46cfa87866',
                item_name: 'Revlon Red Lipstick',
                item_price_each: 4.99,
                item_image: 'linktoimage',
                item_qty: 1,
                item_add_dttm: 1573876779279
            }
        ]
    }
]

*/