//the vendor slice for state

import { createSlice } from '@reduxjs/toolkit'

const vendorSlice = createSlice({
    name: 'vendor',
    initialState: { vendorGeoJson: {} },
    reducers: {
        setVendors(state, action) { //payload is { vendorGeoJson: Object }
            state.vendorGeoJson = action.payload.vendorGeoJson
        }
    }
});

const { actions, reducer } = vendorSlice;
export const { setVendors } = actions;
export default reducer;